@keyframes twinkle {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

a {
  text-decoration: none;
}
